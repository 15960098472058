<template>
	<div class="imgs">
		<div class="img" v-for="(v,i) in img" :key="i">
			<img :src="v+'!120.120'" /><span class="del" @click="del(i)"><a-icon type="close"/></span>
		</div>
		<a-upload
	    name="file"
			class="up-img"
	    list-type="picture-card"
	    :show-upload-list="false"
	    :action="api"
	    :before-upload="beforeUpload"
	    @change="handleChange"
	  >
		<a-icon type="plus" />
	  </a-upload>
	</div>
</template>

<script>
export default {
	data(){
		return {
			api : process.env.VUE_APP_IMG_API,
			img:[]
		}
	},
	methods:{
		init(e){
			this.img = e
		},
		del(i){
			this.img.splice(i,1)
		},
		handleChange(info) {
			if (info.file.status === 'uploading') {
				return;
			}
			if (info.file.status === 'done') {
				this.img.push(info.file.response.data)
				this.$emit("ok",this.img)
			}		
		},
		beforeUpload(file) {
			const isLt1M = file.size / 1024 / 1024 < 5;
			if (!isLt1M) {
				this.$message.error('上传图片不能大于5M!');
			}
			return isLt1M;
		}
	}
}
</script>

<style>
.up-img{float:left;display:inline-block;width:auto!important}
.up-img img{width:98px}
.imgs .img{float:left;margin:0 8px 8px 0;position:relative}
.imgs .img img{width:100px;height:100px}
.imgs .img .del{color:#FFFFFF;background:red;display:inline-flex;align-items:center;justify-content:center;position:absolute;top:5px;right:5px;width:20px;border-radius:20px;height:20px;z-index:100}
</style>
