<template>
	<div class="qiqb-config">
		<a-menu mode="inline" class="left" defaultSelectedKeys="0" @click="change">
			<a-menu-item :key="0">应用信息</a-menu-item>
			<a-menu-item :key="1">阿里短信</a-menu-item>
			<a-menu-item :key="2">阿里存储</a-menu-item>
			<a-menu-item :key="3">阿里支付</a-menu-item>
			<a-menu-item :key="4">微信支付</a-menu-item>
		</a-menu>
		<div class="right">
			<a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 13 }">
				<div v-show="curr == 0">
					<a-form-item label="应用标志">
						<Img ref="img" @ok="img_ok"></Img>
						<a-input type="hidden" v-decorator="['img', { rules: [{ required: true, message: '' }] }]" />
					</a-form-item>
					<a-form-item label="应用名称">
						<a-input v-decorator="['title', { rules: [{ required: true, message: '' }] }]" />
					</a-form-item>
					<a-form-item label="应用域名">
						<a-input v-decorator="['api', { rules: [{ required: true, message: '' }] }]" />
					</a-form-item>
					<a-form-item label="客服电话">
						<a-input v-decorator="['phone', { rules: [{ required: true, message: '' }] }]" />
					</a-form-item>
					<a-form-item :wrapper-col="{ offset: 4 }">
						<a-button type="primary" @click="next">保存</a-button>
					</a-form-item>
				</div>
				<div v-show="curr == 1">
					<a-form-item label="授权账号">
						<a-input v-decorator="['sms_key_id']" />
					</a-form-item>
					<a-form-item label="授权密钥">
						<a-input v-decorator="['sms_key_secret']" />
					</a-form-item>
					<a-form-item label="短信签名">
						<a-input v-decorator="['sms_sign']" />
					</a-form-item>
					<a-form-item label="短信模板">
						<a-input v-decorator="['sms_code']" />
					</a-form-item>
					<a-form-item :wrapper-col="{ offset: 4 }">
						<a-button type="primary" @click="next">保存</a-button>
					</a-form-item>
				</div>
				<div v-show="curr == 2">
					<a-form-item label="授权账号">
						<a-input v-decorator="['oss_key_id']" />
					</a-form-item>
					<a-form-item label="授权密钥">
						<a-input v-decorator="['oss_key_secret']" />
					</a-form-item>
					<a-form-item label="地域节点">
						<a-input v-decorator="['oss_endpoint']" />
					</a-form-item>
					<a-form-item label="空间名称">
						<a-input v-decorator="['oss_bucket']" />
					</a-form-item>
					<a-form-item label="绑定域名">
						<a-input v-decorator="['oss_url']" />
					</a-form-item>
					<a-form-item :wrapper-col="{ offset: 4 }">
						<a-button type="primary" @click="next">保存</a-button>
					</a-form-item>
				</div>
				<div v-show="curr == 3">
					<a-form-item label="应用编号">
						<a-input v-decorator="['alipay_appid']" />
					</a-form-item>
					<a-form-item label="开发者密钥">
						<a-textarea v-decorator="['alipay_rsa_private_key']" />
					</a-form-item>
					<a-form-item label="应用公钥">
						<a-textarea v-decorator="['alipay_rsa_public_key']" />
					</a-form-item>
					<a-form-item label="应用回调">
						<a-input v-decorator="['alipay_notify_url']" />
					</a-form-item>
					<a-form-item :wrapper-col="{ offset: 4 }">
						<a-button type="primary" @click="next">保存</a-button>
					</a-form-item>
				</div>
				<div v-show="curr == 4">
					<a-form-item label="应用编号">
						<a-input v-decorator="['wxpay_appid']" />
					</a-form-item>
					<a-form-item label="小程序编号">
						<a-input v-decorator="['wxpay_wechat']" />
					</a-form-item>
					<a-form-item label="商户编号">
						<a-input v-decorator="['wxpay_mchid']" />
					</a-form-item>
					<a-form-item label="应用密钥">
						<a-input v-decorator="['wxpay_key']" />
					</a-form-item>
					<a-form-item label="应用回调">
						<a-input v-decorator="['wxpay_notify_url']" />
					</a-form-item>
					<a-form-item :wrapper-col="{ offset: 4 }">
						<a-button type="primary" @click="next">保存</a-button>
					</a-form-item>
				</div>
			</a-form>
		</div>
	</div>
</template>

<script>
import pick from "lodash.pick"
import Img from "../../../components/Img.vue"
import Imgs from "../../../components/Imgs.vue"
import Editor from "../../../components/Editor.vue"

import { config_index, config_add } from "../../../api/index.js";

const fields = ['img', 'title', 'api', 'phone', 'service_price', 'distance', 'info', 'wxpay_appid', 'wxpay_wechat', 'wxpay_mchid', 'wxpay_key', 'wxpay_notify_url', 'alipay_appid', 'alipay_rsa_private_key', 'alipay_rsa_public_key', 'alipay_notify_url', 'oss_key_id', 'oss_key_secret', 'oss_endpoint', 'oss_bucket', 'oss_url', 'sms_key_id', 'sms_key_secret', 'sms_sign', 'sms_code']


export default {
	components: {
		Img, Imgs, Editor
	},
	methods: {
		async more(e) {
			let res = await config_index()
			if (res.code == 200) {
				this.$nextTick(() => {
					this.form.setFieldsValue(pick(res.data, fields))
					this.$refs.img.init(res.data.img)
					this.$refs.editor.init(res.data.info)
				})
			}
		},
		next() {
			this.form.validateFields((errors, values) => {
				if (!errors) {
					config_add(values).then((e) => {
						if (e.code == 200) {
							this.$message.success('保存成功~');
						} else {
							this.$message.error(e.msg);
						}
					})
				}
			})
		},
		change(e) {
			this.curr = e.key
		},
		img_ok(e) {
			this.form.setFieldsValue({ img: e })
		},
		imgs_ok(e) {
			this.form.setFieldsValue({ imgs: e })
		},
		editor_ok(e) {
			this.form.setFieldsValue({ info: e })
		}
	},
	created() {
		fields.forEach((v) => this.form.getFieldDecorator(v))
	},
	mounted() {
		this.more()
	},
	data() {
		return {
			name: "",
			form: this.$form.createForm(this),
			item: [],
			curr: 0
		};
	}
};
</script>

<style>
.qiqb-config {
	background: #FFF;
	display: flex;
	flex-direction: row;
	padding-left: 200px;
	position: relative;
}

.qiqb-config .left {
	width: 200px;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	border: none !important;
}

.qiqb-config .left .ant-menu-item {
	margin: 0 !important;
}

.qiqb-config .right {
	width: 990px;
	min-height: 700px;
	padding: 50px 0;
	border-left: 1px solid #f0f0f0;
}</style>